import { computed } from '@nuxtjs/composition-api';

import { MODAL_NAMES } from '~/components/common/SimpleModal/constants';
import {
  useAttributes, useCashback, useCompatibleTitle, useMagentoConfiguration, useProductPageState, useUiState,
} from '~/composables';
import { PRODUCT_TABS } from '~/composables/useProductTabs';
import { getBrandContent } from '~/helpers/brandTooltipHelpers';
import isServer from '~/helpers/isServer';
import { isGenuine, isPrinter } from '~/helpers/productHelpers';
import { smoothScrollToElement } from '~/helpers/smoothScrollToElement';

import { UseBulletsContentInterface, UseBulletsParams } from './useProductBullets';

function useBulletsContent({ product }: UseBulletsParams): UseBulletsContentInterface {
  const { toggleModal, toggleAdditionalModal } = useUiState();
  const { getOptionLabel } = useAttributes();
  const { magentoBaseUrl } = useMagentoConfiguration();

  const cartridgeCondition = computed(() => getOptionLabel('cartridge_condition', product.cartridge_condition));
  const tooltipContent = computed(() => getBrandContent(product, cartridgeCondition.value, magentoBaseUrl.value));
  const hasReturnProgramDetails = computed(() => product.headline && product.return_program_hover_show?.length > 0);

  const openBrandHoverModal = (isAdditional) => {
    let modalFnc = toggleModal;

    if (isAdditional) {
      modalFnc = toggleAdditionalModal;
    }

    modalFnc({
      identifier: MODAL_NAMES.manufacturerInfo,
      props: {
        tooltipContent: tooltipContent.value,
      },
    });
  };

  return {
    hasReturnProgramDetails,
    tooltipContent,
    openBrandHoverModal,
  };
}

export function useProductBullets({ product, compatibilityBrand }: UseBulletsParams) {
  const { toggleModal } = useUiState();
  const { getOptionLabel } = useAttributes();
  const { setActiveTab } = useProductPageState();
  const {
    hasReturnProgramDetails, tooltipContent, openBrandHoverModal,
  } = useBulletsContent({
    product,
  });
  const { hasCashback } = useCashback(product);
  const { compatibleTitle } = useCompatibleTitle(product);

  const isProductGenuine = computed<boolean>(() => isGenuine(product));
  const isPrinterProduct = computed<boolean>(() => isPrinter(product));
  const skuComponentTitle = computed(() => (product.sku_pack_component ? `Contains ${product.sku_pack_component}` : null));
  const altProduct = computed(() => product.alternative_product);
  const hasCompatibleSaves = computed(() => !isProductGenuine.value && altProduct.value?.stock?.is_in_stock);
  const isPromotion = computed(() => {
    const type = getOptionLabel('specification_product_type', product.specification_product_type);

    if (!type) return false;

    return type.toLowerCase().includes('promotion');
  });

  const openModal = (identifier: string, type?: string, title?: string) => {
    toggleModal({
      identifier,
      props: {
        brand: compatibilityBrand,
        type,
        title,
      },
    });
  };
  const openNotCompatibleModal = () => {
    toggleModal({
      identifier: MODAL_NAMES.manufacturerInfo,
      props: {
        tooltipContent: {
          title: 'Not compatible with HP+',
          text: 'If HP+ has been enabled on your printer, these cartridges will not be recognised.',
        },
      },
    });
  };

  const showCompatibilityList = () => {
    setActiveTab(PRODUCT_TABS.compatibility);
    smoothScrollToElement(`#${PRODUCT_TABS.compatibility}`);
  };

  return {
    MODAL_NAMES,
    skuComponentTitle,
    hasCompatibleSaves,
    hasReturnProgramDetails,
    isProductGenuine,
    isPrinterProduct,
    isPromotion,
    tooltipContent,
    hasCashback,
    altProduct,
    compatibleTitle,
    clientSide: computed(() => !isServer),
    openModal,
    showCompatibilityList,
    openBrandHoverModal,
    openNotCompatibleModal,
  };
}
