import { useAttributes } from '~/composables/useAttributes';
import { CARTRIDGE_REFURBISHED, PRINTER_REFURBISHED } from '~/constants';
import { getProductAssetsDomain } from '~/modules/catalog/category/components/tiles/assetsHelper';
import { ProductInterface, ProductReview } from '~/modules/GraphQL/types';

import CONFIG from './config';
import { useHelpers } from './useHelpers';
import { Breadcrumbs, UseStructuredDataInterface } from './useStructuredData';

export function useStructuredData(): UseStructuredDataInterface {
  const { getReviewJsonLd, getText, removeEmptyFields } = useHelpers();
  const { getOptionLabel } = useAttributes();

  const collectProductJsonLd = (product: ProductInterface, reviews: ProductReview[]) => {
    const brand = getOptionLabel('specification_brand', product.specification_brand);

    const reviewsMarkup = {
      review: getReviewJsonLd(reviews, product.name),
    };
    let productRatingMarkup;

    if (reviews && product.review_count > 0) {
      productRatingMarkup = {
        aggregateRating: {
          '@type': 'AggregateRating',
          ratingValue: product.rating_summary ? product.rating_summary.toFixed(2) : 0,
          bestRating: 100,
          worstRating: 0,
          reviewCount: product.review_count,
        },
      };
    }

    const nonRequiredFields = [
      'name',
      'brand',
      'image',
      'sku',
      'description',
    ];
    const image = product.media_gallery_entries?.[0]?.file;

    const nonProcessedObject: object = {
      '@context': 'https://schema.org',
      '@type': 'Product',
      name: product.name,
      brand,
      image: image ? getProductAssetsDomain(image) : image,
      sku: product.sku,
      description: getText(product.description.html),
      offers: {
        '@type': 'Offer',
        priceCurrency: 'GBP',
        price: Number.parseFloat(`${product.price.minimalPrice.amount.value}`).toFixed(2),
        url: product.url_path,
        itemCondition: +product.printer_condition === PRINTER_REFURBISHED || product.cartridge_condition === CARTRIDGE_REFURBISHED ? 'RefurbishedCondition' : 'NewCondition',
        availability: product.stock?.is_in_stock ? 'InStock' : 'OutOfStock',
        shippingDetails: {
          '@type': 'OfferShippingDetails',
          shippingRate: {
            '@type': 'MonetaryAmount',
            value: 0,
            currency: 'GBP',
          },
          shippingDestination: {
            '@type': 'DefinedRegion',
            addressCountry: 'GB',
          },
          deliveryTime: {
            '@type': 'ShippingDeliveryTime',
            handlingTime: {
              '@type': 'QuantitativeValue',
              minValue: 0,
              maxValue: 0,
              unitCode: 'DAY',
            },
            transitTime: {
              '@type': 'QuantitativeValue',
              minValue: 1,
              maxValue: 1,
              unitCode: 'DAY',
            },
          },
        },
      },
      ...productRatingMarkup,
      ...reviewsMarkup,
    };

    return JSON.stringify(removeEmptyFields(nonProcessedObject, nonRequiredFields));
  };

  const collectBreadcrumbsJsonLd = (fullBreadcrumbs: Breadcrumbs[], currentPageName?: string, isProductPage?: boolean) => {
    const listConfig = CONFIG.default.breadcrumbs;
    const structuredBreadcrumbsRoutes = { itemListElement: [] };

    const breadcrumbs = isProductPage ? fullBreadcrumbs.slice(0, 2) : fullBreadcrumbs;
    const namePrefix = breadcrumbs?.[0]?.text || '';
    const isPaperPage = namePrefix.toLowerCase() === 'paper';
    const currentBreadcrumb = currentPageName || fullBreadcrumbs?.[fullBreadcrumbs.length - 1]?.text;
    const categoryNameFormatted = !isPaperPage ? `${currentBreadcrumb} ${namePrefix}` : `${currentBreadcrumb}`;

    const getNameWithPrefix = (name, index) => (index > 0 && !isPaperPage ? `${name} ${namePrefix}` : name);

    if (currentBreadcrumb) {
      if (breadcrumbs.length > 0) {
        breadcrumbs.forEach((breadcrumb, index) => {
          structuredBreadcrumbsRoutes.itemListElement.push({
            '@type': 'ListItem',
            position: index + 1,
            item:
              {
                '@type': 'WebPage',
                '@id': breadcrumb.link,
                name: getNameWithPrefix(breadcrumb.text, index),
              },
          });
        });
      }

      if (!isProductPage) {
        structuredBreadcrumbsRoutes.itemListElement.push(
          {
            '@type': 'ListItem',
            position: breadcrumbs.length + 1,
            name: categoryNameFormatted,
          },
        );
      }
    }

    if (structuredBreadcrumbsRoutes.itemListElement.length > 0) {
      structuredBreadcrumbsRoutes.itemListElement.unshift(listConfig.itemListElement[0]);
    }

    return JSON.stringify({
      '@context': 'https://schema.org',
      '@type': 'BreadcrumbList',
      ...structuredBreadcrumbsRoutes,
    });
  };

  const collectOrganizationJsonLd = () => JSON.stringify({
    '@context': 'https://schema.org',
    '@type': 'Corporation',
    ...CONFIG.default.organization,
  });

  const collectFaqJsonLd = (faqConfig: object) => JSON.stringify({
    '@context': 'https://schema.org',
    '@type': 'FAQPage',
    ...faqConfig,
  });

  return {
    collectBreadcrumbsJsonLd,
    collectProductJsonLd,
    collectOrganizationJsonLd,
    collectFaqJsonLd,
  };
}

export default useStructuredData;
export * from './useStructuredData';
