export const ATTRIBUTES_LIST = [
  {
    entity_type: '4',
    attribute_code: 'specification_product_type',
  },
  {
    entity_type: '4',
    attribute_code: 'standard_interfaces',
  },
  {
    entity_type: '4',
    attribute_code: 'specification_brand',
  },
  {
    entity_type: '4',
    attribute_code: 'group',
  },
  {
    entity_type: '4',
    attribute_code: 'cartridge_condition',
  },
  {
    entity_type: '4',
    attribute_code: 'specification_colour',
  },
  {
    entity_type: '4',
    attribute_code: 'printing',
  },
  {
    entity_type: '4',
    attribute_code: 'standard_interfaces',
  },
  {
    entity_type: '4',
    attribute_code: 'duplex_functions',
  },
  {
    entity_type: '4',
    attribute_code: 'conformity_marks',
  },
  {
    entity_type: '4',
    attribute_code: 'maximum_iso_a_series_paper',
  },
];
