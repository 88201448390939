






































































import {
  defineComponent, ref,
} from '@nuxtjs/composition-api';

import SvgImage from '~/components/General/SvgImage.vue';
import { useHtmlEscaper } from '~/composables/useHtmlEscaper';
import { APP_ROUTER_PATHS } from '~/constants';

export default defineComponent({
  name: 'NotFound',
  components: { SvgImage },
  setup() {
    const searchInput = ref<string>('');
    const { stripTags } = useHtmlEscaper();

    const handleSearch = () => {
      const searchInputValue = stripTags(searchInput.value);

      if (searchInputValue) {
        window.location.replace(`/search?q=${searchInputValue}`);
      }
    };

    return {
      searchInput,
      homePath: APP_ROUTER_PATHS.home,
      contactPath: APP_ROUTER_PATHS.contact,
      handleSearch,
    };
  },
});
