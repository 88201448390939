export const DYNAMIC_CATEGORIES = {
  popular_brands: [
    {
      attribute_label: 'Brother',
      visible_name: 'Brother Printers',
      url_path: 'printers/Brother.html',
      attribute_code: 'specification_brand',
      attribute_id: '1947',
    },
    {
      attribute_label: 'Canon',
      visible_name: 'Canon Printers',
      url_path: 'printers/Canon.html',
      attribute_code: 'specification_brand',
      attribute_id: '1948',
    },
    {
      attribute_label: 'Epson',
      visible_name: 'Epson Printers',
      url_path: 'printers/Epson.html',
      attribute_code: 'specification_brand',
      attribute_id: '1989',
    },
    {
      attribute_label: 'HP',
      visible_name: 'HP Printers',
      url_path: 'printers/HP.html',
      attribute_code: 'specification_brand',
      attribute_id: '1991',
    },
  ],
  other_brands: [
    {
      attribute_label: 'Dymo',
      visible_name: 'Dymo Printers',
      url_path: 'printers/Dymo.html',
      attribute_code: 'specification_brand',
      attribute_id: '1988',
    },
    {
      attribute_label: 'Kyocera',
      visible_name: 'Kyocera Printers',
      url_path: 'printers/Kyocera.html',
      attribute_code: 'specification_brand',
      attribute_id: '1996',
    },
    {
      attribute_label: 'Lexmark',
      visible_name: 'Lexmark Printers',
      url_path: 'printers/Lexmark.html',
      attribute_code: 'specification_brand',
      attribute_id: '1997',
    },
    {
      attribute_label: 'Oki',
      visible_name: 'Oki Printers',
      url_path: 'printers/Oki.html',
      attribute_code: 'specification_brand',
      attribute_id: '1999',
    },
    {
      attribute_label: 'Xerox',
      visible_name: 'Xerox Printers',
      url_path: 'printers/Xerox.html',
      attribute_code: 'specification_brand',
      attribute_id: '2015',
    },
  ],
  categories: [
    {
      attribute_label: 'Dot matrix',
      visible_name: 'Dot matrix Printers',
      url_path: 'printers/Dot-matrix.html',
      attribute_code: 'specification_product_type',
      attribute_id: '2944',
    },
    {
      attribute_label: 'Inkjet',
      visible_name: 'Inkjet Printers',
      url_path: 'printers/Inkjet.html',
      attribute_code: 'specification_product_type',
      attribute_id: '2840',
    },
    {
      attribute_label: 'Label',
      visible_name: 'Label Printers',
      url_path: 'printers/Label.html',
      attribute_code: 'specification_product_type',
      attribute_id: '2912',
    },
    {
      attribute_label: 'Laser',
      visible_name: 'Laser Printers',
      url_path: 'printers/Laser.html',
      attribute_code: 'specification_product_type',
      attribute_id: '2841',
    },
  ],
};

export const DYNAMIC_CATEGORIES_GROUPS = [
  'popular_brands',
  'categories',
  'other_brands',
];

export const DYNAMIC_CATEGORY_ATTRIBUTES = ['specification_brand', 'specification_product_type'];
export const PRINTERS_HIDDEN_ATTRIBUTES = ['colour'];
