






























import {
  computed,
  defineComponent,
} from '@nuxtjs/composition-api';

import { MODAL_NAMES, SimpleModal } from '~/components/common/SimpleModal';
import { useUiState } from '~/composables';

export default defineComponent({
  name: 'BulletModal',
  components: {
    SimpleModal,
  },
  setup() {
    const { modal } = useUiState();

    const modalProps = computed(() => modal.value.props || {});
    const modalType = computed(() => modalProps.value.type || 'default');
    const content = computed(() => ([
      {
        key: 'default',
        title: '3-year warranty on our own-brand cartridges',
        content: [
          {
            text: 'We are proud of our own-brand cartridges, so we’ve made trying them a no-brainer. If within three years of purchase you have any problem whatsoever, we’ll happily offer either a replacement or a refund.',
          },
          {
            text: 'Returning items is easy—it takes just a few minutes. But our return rate is very low, so you’ll be unlikely to need it.',
          },
          {
            text: 'We are confident that no other own-brand cartridges are better than ours. They produce vibrant prints with accurate colour densities and dark blacks—and their page yields are as good as the genuines, if not better. Give them a try, and see why our customers love them.',
            beforeStrong: true,
          },
          {
            strong: 'Our own-brand cartridges are incredibly reliable for three reasons:',
          },
          {
            text: '1. We are the UK’s biggest seller of own-brand cartridges, and we have invested a lot in optimising them.',
          },
          {
            text: '2. We are dedicated to quality. Every own-brand cartridge is performance-tested before it leaves the factory.',
          },
          {
            text: '3. We constantly monitor customer feedback, and quickly resolve any issues.',
          },
        ],
      },
      {
        key: 'warranty',
        title: '12-month warranty',
        content: [
          {
            text: 'If within a year of purchase you happen to experience any quality problem, we\'ll happily provide a replacement, or a full refund.',
          },
        ],
      },
      {
        key: 'returnProgram',
        title: modalProps.value.title,
        content: [
          {
            text: 'Lexmark return programme cartridges are sold at a discount compared with the regular cartridges in exchange for your commitment, to Lexmark, to return the empty cartridge. Return programme cartridges are otherwise identical to the regular Lexmark cartridges and are made from 100% new parts.',
          },
          {
            text: 'When you receive and open your return programme cartridge you will be agreeing to Lexmark’s terms and conditions, found at Lexmark.com/recycling.',
            strong: 'Please ensure you have read the terms and procedures in full before purchasing and opening each cartridge.',
            additionalText: 'The programme may not be suitable for users with very low print volumes.',
          },
        ],
      },
      {
        key: 'protection',
        title: 'Our cartridges won\'t damage your printer—guaranteed',
        content: [
          {
            text: 'Some people worry that own-brand cartridges might damage their printers. We know from experience that ours don’t.',
          },
          {
            text: 'To reassure you, we guarantee that we’ll repair or replace your printer—for free—in the unlikely event that it gets damaged by an own-brand cartridge. This is regardless of how old your printer is. We can afford to offer this because problems are almost unheard of.',
          },
          {
            text: 'Some people whose printers are less than a year old worry that an own-brand cartridge might invalidate the manufacturer’s warranty. This isn’t true. By law, the manufacturers aren’t allowed to invalidate your warranty if you use own-brand cartridges. If something does go wrong, and our own-brand cartridges are to blame, we’ll take over the manufacturer’s warranty, offer you phone support and repair or replace your printer if needed.',
          },
          {
            strong: 'In summary, there’s zero risk in using our own-brand cartridges.',
            offset: true,
          },
        ],
      },
      {
        key: 'refurbished',
        title: 'Professionally refurbished printer',
        content: [
          {
            text: 'Product works and looks like new. Refurbished by the original manufacturer. Comes with a 1 year warranty.',
          },
        ],
      },
      {
        key: 'printSpeed',
        title: modalProps.value.title,
        content: [
          {
            text: modalProps.value.content,
          },
        ],
      },
      {
        key: 'cartridgesIncluded',
        title: modalProps.value.title,
        content: [
          {
            text: 'For most cartridges this is based on 5% coverage on A4 paper. These numbers are based on manufacturer quoted values and are for comparison purposes only.',
          },
        ],
      },
    ]));

    const getStrongTextStyles = (item) => {
      if (item.beforeStrong) {
        return 'pt-5 pb-8 leading-[1.42]';
      }

      if (item.strong && item.offset) {
        return 'pt-5 leading-[1.42]';
      }

      if (item.strong) {
        return 'leading-[1.42]';
      }

      return 'pt-5 text-base leading-[1.42] md_text-base';
    };

    return {
      modalProps,
      modalType,
      content,
      name: MODAL_NAMES.bullet,
      getStrongTextStyles,
    };
  },
});
