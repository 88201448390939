import { MediaGalleryEntry } from '~/modules/GraphQL/types';

export const sortMediaGalleryItems = (arr: MediaGalleryEntry[]): MediaGalleryEntry[] => {
  let imageItem = null;

  const itemsWithoutImage = arr.reduce((acc, item) => {
    if (item.types.includes('image')) {
      imageItem = item;
    } else {
      acc.push(item);
    }
    return acc;
  }, []);

  itemsWithoutImage.sort((a, b) => a.position - b.position);

  return imageItem ? [imageItem, ...itemsWithoutImage] : itemsWithoutImage;
};
