import {
  computed, onMounted, ref, ssrRef, useContext, useFetch, useRoute, watch, useRouter
} from '@nuxtjs/composition-api';
import { useCache } from '@vue-storefront/cache';

import {
  Pagination, useFacet, usePageHelpers, useUiHelpers, useUiState,
} from '~/composables';
import { PRINTERS_PAGE_SIZE } from '~/constants';
import { getListingTags } from '~/helpers/cacheHelpers';
import { handleGTMEvent } from '~/helpers/gtmHelpers';
import isServer from '~/helpers/isServer';
import { getDynamicCategory } from '~/helpers/middleware/pagePreloadHelpers';
import { SortingModel } from '~/modules/catalog/category/composables/useFacet/sortingOptions';
import facetGetters from '~/modules/catalog/category/getters/facetGetters';
import { ProductInterface } from '~/modules/GraphQL/types';
import { useLoadingStore } from '~/stores/loading';
import { usePageStore } from '~/stores/page';

import { UseProductWithFiltersInterface } from './useProductWithFilters';
let isAjaxReload = false;

export function useProductWithFilters(isSearch?: boolean): UseProductWithFiltersInterface {
  const { routeData, initialData } = usePageStore();
  const { addTags } = useCache();
  const uiHelpers = useUiHelpers();
  const route = useRoute();
  const { isSearchPage } = usePageHelpers();
  const { redirect: NuxtRedirect, from } = useContext();

  const loadingStore = useLoadingStore();
  const router = useRouter();
  const isPending = computed(() => loadingStore.isLoading);

  const isShowProducts = ref<boolean>(false);
  const currentPage = ref<number>(1);
  const products = ssrRef<any[]>([]);
  const filters = ssrRef<any[]>([]);
  const sortBy = ref<SortingModel>({ selected: '', options: [] });
  const pagination = ref<Pagination>({});

  const dynamicCategory = computed(() => getDynamicCategory(route.value.path));

  const {
    toggleFilterSidebar,
    setFilterSidebar,
    isFilterSidebarOpen,
  } = useUiState();

  const { result: resultData, search } = useFacet();

  const isLoading = ref(false);
  const isForceLoad = ref(false);
  const isInitialLoad = ref(!from.value);
  const categoryUid = routeData?.uid;
  const result = ref(initialData);

  const { fetch } = useFetch(async () => {
    isLoading.value = true;
    const facetsFromUrl = uiHelpers.getFacetsFromURL();
    currentPage.value = facetsFromUrl.page;

    if (!isServer) {
      globalThis.scrollTo({ top: 0, behavior: 'smooth' });
    }

    if (!initialData || isForceLoad.value) {
      await search({
        ...facetsFromUrl,
        ...(categoryUid && route.value.name !== 'search' ? { category_uid: categoryUid } : {}),
        ...(dynamicCategory.value ? { dynamicCategory: dynamicCategory.value } : {}),
        itemsPerPage: PRINTERS_PAGE_SIZE,
        isSearchPage: route.value.name === 'search',
      });

      result.value = resultData.value;
    }

    isInitialLoad.value = true;

    if (isSearchPage.value && result.value?.data?.redirectUrl) {
      isLoading.value = false;
      NuxtRedirect(301, result.value?.data?.redirectUrl);
      return;
    }

    products.value = facetGetters.getProducts(result.value) ?? [];
    filters.value = facetGetters.getFilters(result.value, isSearchPage.value) ?? [];
    sortBy.value = facetGetters.getSortOptions(result.value);
    pagination.value = facetGetters.getPagination(result.value);
    isShowProducts.value = true;
    isLoading.value = false;

    addTags(getListingTags(products.value as ProductInterface[], categoryUid && route.value.name !== 'search'));
  });

  const isEmptySearch = computed(() => products.value.length === 0 && isShowProducts.value && isSearchPage.value);

  const goToPage = (page: number) => {
    isAjaxReload = true;
    currentPage.value = page;
    uiHelpers.changePage(page, false);
    isForceLoad.value = true;
    fetch();
  };

  const onReloadProducts = () => {
    currentPage.value = 1;
    goToPage(0);
    isAjaxReload = true;
  };

  onMounted(() => {
    const query = route.value.query.q;
    //If this is page reload
    if (!isAjaxReload) {
      const query = new URLSearchParams( route.value.fullPath.replace(route.value.path, "") )
      //if we have price filter we have to delete it
      if (query.get('price') || query.get('print_speed_filters')) {
        query.delete('price')
        query.delete('print_speed_filters')
        router.push(route.value.path + '?' + query.toString())
      }
    }

    isAjaxReload = true;
    if (isSearch && result.value.data?.isFallback && query) {
      handleGTMEvent('fallbackSearch', {
        search: {
          query,
        },
      });
    }
  });

  watch(
    route,
    () => {
      products.value = [];
      filters.value = [];
      pagination.value = {};
      isForceLoad.value = false;

      if (isFilterSidebarOpen) {
        setFilterSidebar(false);
      }
    },
    {
      immediate: true,
    },
  );

  return {
    isFilterSidebarOpen,
    products,
    filters,
    sortBy,
    isShowProducts,
    routeData,
    pagination,
    isLoading,
    isPending,
    dynamicCategory,
    isEmptySearch,
    currentPage,
    toggleFilterSidebar,
    onReloadProducts,
    goToPage,
  };
}

export default useProductWithFilters;
export * from './useProductWithFilters';
