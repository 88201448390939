export const recaptchaValidator = async (context, token) => {
  try {
    const { secretkey } = context.config.recaptcha;
    const url = `https://www.google.com/recaptcha/api/siteverify?secret=${secretkey}&response=${token}`;
    const result = await fetch(url);

    return await result.json();
  } catch (error) {
    throw error.message || error;
  }
};
